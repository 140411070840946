<template>
  <div style="display:inline-flex">
    <input ref="input" v-on="listeners" style="width: 100%" :required="required"
      :readonly="readonly">
  </div>
</template>

<script>
export default {
  name: "s-numberinput",

  props: {
    value: [Number, String],
    decimals: {
      type: [Number, String],
      default: 2,
    },
    decPoint: {
      type: String,
      default: ".",
    },
    thousandsSep: {
      type: String,
      default: "",
    },
    min: Number,
    max: Number,
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    selectonfocus: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    listeners() {
      // deno-lint-ignore no-unused-vars
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
  },

  watch: {
    value(novo) {
      this.setValue(novo);
    },
  },

  methods: {
    setValue(novo) {
      // caso for "-" e numberValue (null or zero)
      const elm = this.$el.querySelector("input:nth-child(2)");
      if (elm.value === "-" && !this.$refs.input.numberValue) {
        return;
      }
      this.$refs.input.numberValue = novo;
    },

    onFocus() {
      this.selectonfocus && this.$refs.input?.select?.();
    },
  },

  beforeDestroy() {
    jQuery(this.$el).off();
    if (this.selectonfocus) {
      this.$refs.input?.removeEventListener?.("focus", this.onFocus);
    }
  },

  mounted() {
    const options = jQuery.extend(this.$props, {
      change: (val) => {
        // adaptado NULL aqui (se fizer o componente numberinput retornar NULL quebra com a utilização atual)
        const value = (this.$refs.input.value === "" && val === 0) ? null : val;
        this.$emit("input", value);
        (this.value !== value) && this.$emit("changenumber", value);
      },
    });

    const $el = jQuery(this.$refs.input);
    $el.numberinput(options);
    this.setValue(this.value);

    if (this.$el.style.textAlign) {
      this.$refs.input.style.textAlign = this.$el.style.textAlign;
    }

    if (this.selectonfocus) {
      this.$refs.input?.addEventListener?.("focus", this.onFocus);
    }
  },
};
</script>