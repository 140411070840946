import "./publicPath";
import Vue from "vue";
import moment from "moment";
import jQuery from "jquery";
import Inputmask from "inputmask";
import Yanda from "yanda";
import Siggma from "./siggma";
import Painel from "./utils/painel";
import Notify from "./notify.js";
import "./configVue";
import "fullcalendar";
import "fullcalendar-scheduler";
import "fullcalendar/dist/locale/pt-br";
import "fullcalendar/dist/fullcalendar.min.css";
import "fullcalendar-scheduler/dist/scheduler.min.css";
import "./css/index.css";
import "@mdi/font/css/materialdesignicons.min.css";

import { GridStack } from "gridstack";
import "gridstack/dist/gridstack.css";

// deno-lint-ignore no-process-global
const isProd = process.env.NODE_ENV === "production";

globalThis.Vue = Vue;
globalThis.jQuery = jQuery;
globalThis.moment = moment;
globalThis.Inputmask = Inputmask;
globalThis.Siggma = Siggma;
globalThis.Yanda = Yanda;
globalThis.GridStack = GridStack;
globalThis.Notify = Notify;

// Utilizado para controlar o menu
globalThis.Painel = Painel;

require("jquery-migrate");
jQuery.migrateMute = isProd;

// Necessário ao atualizar jQuery da versão 3.4 para 3.5
// A princípio, unico lugar que necessita desse ajuste, é na agenda na view "semana"
jQuery.UNSAFE_restoreLegacyHtmlPrefilter();

require("inputmask/dist/inputmask/jquery.inputmask.js");
require("./utils/inputmaskPhone.js");
