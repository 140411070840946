var render = function render(){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"table-cell",style:(_vm.tableCellStyle),on:{"click":_vm.handleClick}},[(_vm.head && _vm.sortable)?_c('div',{class:_vm.tableCellContentClass},[_vm._t("default",function(){return [_c('span',[_vm._v("field_name")])]}),_vm._v(" "),_c('i',{class:[
      'table-cell-icon',
      's-icon',
      'mdi',
      _vm.isDesc ? 'mdi-arrow-up' : 'mdi-arrow-down',
      'mdi-18px'
    ],style:(!_vm.direction ? 'visibility: hidden' : undefined)})],2):_c('div',{class:_vm.tableCellContentClass},[_vm._t("default",function(){return [_c('span',[_vm._v("field_name")])]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }