import enterNext from "./directives/enterNext";
import title from "./directives/title";

const components = [];

// https://webpack.js.org/guides/dependency-management/#context-module-api
const requireComponent = require.context("./components", true, /\.vue$/);
requireComponent.keys().forEach((fileName) => {
  const component = requireComponent(fileName).default;
  if (component.name) {
    components.push(component);
  }
});

const loading = {
  template:
    '<div style="height:100%;width:100%;flex:1;display:flex"><s-spinner></s-spinner></div>',
};

const error = {
  template:
    `<div style="height:100%;width:100%;flex:1;display:flex;align-items:center;justify-content:center">
        Desculpe! Não foi possível carregar o componente.<br>Você está conectado na internet?
    </div>`,
};

export const AsyncComponent = (comp) => () => ({
  component: comp(),
  loading,
  error,
  delay: 200,
  timeout: 10000,
});

export default {
  install(Vue, Siggma) {
    Vue.prototype.$siggma = Siggma;

    components.map((component) => Vue.component(component.name, component));

    Vue.component("s-config", AsyncComponent(() => import("./views/config")));
    Vue.component(
      "s-pagamentos",
      AsyncComponent(() => import("./views/pagamentos")),
    );
    Vue.component(
      "s-petshop-dashboard",
      AsyncComponent(() => import("./views/petshop/dashboard")),
    );
    Vue.component(
      "s-petshop-ficha",
      AsyncComponent(() => import("./views/petshop/ficha")),
    );
    Vue.component(
      "s-petshop-mapa-execucao",
      AsyncComponent(() => import("./views/petshop/mapa-execucao")),
    );
    Vue.component(
      "s-petshop-prescricoes-modelos",
      AsyncComponent(() => import("./views/petshop/prescricoes-modelos")),
    );
    Vue.component(
      "s-petshop-entregas",
      AsyncComponent(() => import("./views/petshop/entregas")),
    );
    Vue.component(
      "s-petshop-ficha-cadastro",
      AsyncComponent(() => import("./views/petshop/ficha-cadastro")),
    );
    Vue.component(
      "s-petshop-drsnoopy-config",
      AsyncComponent(() => import("./views/petshop/drsnoopy-config")),
    );
    Vue.component(
      "s-ecommerce",
      AsyncComponent(() => import("./views/ecommerce")),
    );
    Vue.component(
      "s-gestao-obras",
      AsyncComponent(() => import("./views/gestao-obras")),
    );
    Vue.component(
      "s-outros-acip",
      AsyncComponent(() => import("./views/outros-acip/SOutrosAcip")),
    );
    Vue.component(
      "s-lista-presentes",
      AsyncComponent(() =>
        import("./views/lista-presentes/SOutrosListaPresentes")
      ),
    );
    Vue.component(
      "s-comodatos-despesas",
      AsyncComponent(() =>
        import("./views/comodatos-despesas/SComodatosDespesas")
      ),
    );
    Vue.component(
      "s-pessoas",
      AsyncComponent(() => import("./views/pessoas/SPessoas")),
    );
    Vue.component(
      "s-tipos-venda-smart",
      AsyncComponent(() => import("./views/petshop/vendas/STiposVendaSmart")),
    );
    Vue.component("s-stone", AsyncComponent(() => import("./views/stone")));
    Vue.component(
      "s-zetta-payment-config",
      AsyncComponent(() => import("./views/zetta-payment/config")),
    );
    Vue.component(
      "s-zetta-whats-config",
      AsyncComponent(() => import("./views/zetta-whats")),
    );
    Vue.component(
      "s-dashboard",
      AsyncComponent(() => import("./views/dashboard")),
    );
    Vue.component(
      "s-smart-delivery-config",
      AsyncComponent(() => import("./views/smart-delivery/config")),
    );
    Vue.component(
      "s-tributacao-itens",
      AsyncComponent(() => import("./views/itens/STributacaoItens")),
    );
    Vue.component(
      "s-importacao-dados",
      AsyncComponent(() => import("./views/importacao-exportacao/index")),
    );
    Vue.component(
      "s-consultar-documentos",
      AsyncComponent(() => import("./views/consultar-documentos/index")),
    );
    Vue.component(
      "s-confere",
      AsyncComponent(() => import("./views/confere/SConfere")),
    );
    Vue.component(
      "s-conf-imp-dav",
      AsyncComponent(() => import("./views/config/impressora")),
    );
    Vue.component(
      "s-gerencia-pix",
      AsyncComponent(() => import("./views/zetta-payment/gerencia")),
    );
    Vue.component(
      "s-telephone-input",
      AsyncComponent(() => import("./views/telephone-input/STelephoneInput")),
    );
    Vue.component(
      "s-gerencia-whats",
      AsyncComponent(() => import("./views/zetta-whats/gerencia")),
    );
    Vue.component(
      "s-gerencia-scanntech",
      AsyncComponent(() => import("./views/gerenciaScanntech")),
    );
    Vue.component(
      "s-venda-info-extra",
      AsyncComponent(() => import("./views/SVendaInfoExtra")),
    );
    Vue.component(
      "s-ajustes-sped-fiscal",
      AsyncComponent(() => import("./views/ajustes-sped-fiscal/index")),
    );
    Vue.component(
      "s-mapa-calor",
      AsyncComponent(() => import("./views/SMapaCalor.vue")),
    );
    Vue.component(
      "s-distribuir-documentos",
      AsyncComponent(() => import("./views/distribuir-documentos/index")),
    );

    Vue.directive("input-mask", {
      bind(el, binding) {
        jQuery(el).inputmask(binding.value, {
          placeholder: " ",
          keepStatic: true,
        });
      },
    });

    Vue.directive("enter-next", enterNext);
    Vue.directive("title", title);

    Vue.directive("resize-observer", {
      bind(el, binding) {
        el["_resizeObserver"] = new ResizeObserver((entries) => {
          for (const entry of entries) {
            binding.value(entry);
          }
        });
        el["_resizeObserver"].observe(el);
      },
      unbind(el) {
        if (el["_resizeObserver"]) {
          el["_resizeObserver"].disconnect();
          el["_resizeObserver"] = null;
        }
      },
    });
  },
};
